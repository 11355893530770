import PropTypes from 'prop-types'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { SessionAuth } from 'supertokens-auth-react/recipe/session'
import { useSessionContext } from 'supertokens-auth-react/recipe/session';

import MainLayout from '../layout/MainLayout';

function ProtectedRoute({ children, roleAllowed }) {
  const navigate = useNavigate()
  let user = useSessionContext()
  if (
     (roleAllowed?.length > 0
      && !roleAllowed.includes(user?.accessTokenPayload?.user?.accountRole))
  ) {
    navigate('/login', { replace: true })
  }
  return (
    <SessionAuth
      requireAuth={true}
      redirectToLogin={() => {
        navigate('/login', { replace: true })
      }}
    >
      { <MainLayout>{children}</MainLayout>}
    </SessionAuth>
  )
}

ProtectedRoute.propTypes = {
  children: PropTypes.any.isRequired,
}

export default ProtectedRoute

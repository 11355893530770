import React from 'react'
import LinearProgress from '@mui/material/LinearProgress'
import ToastMessage from '@/components/common/ToastMessage'
import { ThemeProvider } from '@emotion/react'
import theme from '@/mui/theme'
import { BrowserRouter as Router, useRoutes } from 'react-router-dom'

import UIContext from '@/context/uiContext'
import { Alert, Backdrop, CircularProgress, Snackbar } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'

import useUIStore, { ACTIONS } from './store/uiStore'
import ConfirmDialog from './components/common/ConfirmDialog'
import ProtectedRoute from './components/guard/ProtectedRoute'
import SuperTokens, { SuperTokensWrapper } from 'supertokens-auth-react'
import Session from 'supertokens-auth-react/recipe/session'
import SingleSupplier from './views/suppliers/SingleSupplier'

SuperTokens.init({
  appInfo: {
    appName: 'planner',
    apiDomain: process.env.REACT_APP_SUPERTOKEN_API_DOMAIN || 'https://allocation.peppermint.company',
    websiteDomain: process.env.REACT_APP_SUPERTOKEN_WEB_DOMAIN || 'https://allocation.peppermint.company',
    apiBasePath: process.env.REACT_APP_BASEPATH || '/api/auth',
    websiteBasePath: '/',
  },
  recipeList: [Session.init()],
})

const Login = React.lazy(() => import('./views/Login'))
const Home = React.lazy(() => import('./views/home'))
const Freelancer = React.lazy(() => import('./views/freelancer'))
const Supplier = React.lazy(() => import('./views/suppliers'))
const SupportFunctionReceipts = React.lazy(() => import('./views/support-function'))


const MainView = () => {
  const routes = useRoutes([
    {
      path: '',
      element: (
        <ProtectedRoute>
          <Home />
        </ProtectedRoute>
      ),
      index: true,
    },
    {
      path: 'production-supplier',
      element: (
        <ProtectedRoute roleAllowed={['ADMIN']}>
          <Freelancer />
        </ProtectedRoute>
      ),
    },
    {
      path: 'suppliers',
      children: [
        {
          path: ':id',
          element: (
            <ProtectedRoute roleAllowed={['ADMIN']}>
              <SingleSupplier />
            </ProtectedRoute>
          ),
        },
        {
          path: '',
          element: (
            <ProtectedRoute roleAllowed={['ADMIN']}>
              <Supplier />
            </ProtectedRoute>
          ),
        },
      ]
    },
    {
      path: 'support-function',
      element: (
        <ProtectedRoute roleAllowed={['ADMIN']}>
          <SupportFunctionReceipts />
        </ProtectedRoute>
      ),
    },
    { path: 'login', element: <Login /> },
  ])
  return routes
}

function App() {
  const { state, dispatch } = useUIStore()

  const handleClose = () => {
    dispatch({
      type: ACTIONS.toggleLoading,
      payload: true,
    })
  }

  return (
    <SuperTokensWrapper>

      <Router>
        <UIContext.Provider
          value={{
            state,
            dispatch,
          }}
        >
          <ToastMessage
            message={state.toastMessage.message}
            type={state.toastMessage.type}
            open={state.toastMessage.message !== ''}
            handleClose={() =>
              dispatch({
                type: ACTIONS.setToastMessage,
                payload: { message: '' },
              })
            }
          />
          <ConfirmDialog
            open={state.confirmDialog.open}
            refreshData={state.confirmDialog.refreshData}
            callback={state.confirmDialog.callback}
            description={state.confirmDialog.description}
          />
          <ThemeProvider theme={theme}>
            <React.Suspense
              fallback={(
                <Backdrop
                  sx={{ color: '#fff', zIndex: (t) => t.zIndex.drawer + 1 }}
                  open
                  onClick={handleClose}
                >
                  <CircularProgress color="primary" />
                </Backdrop>
              )}
            >
              {state.loading
                && (state.linearLoading ? (
                  <LinearProgress className="my-4" />
                ) : (
                  <Snackbar
                    open={state.loading}
                    onClose={handleClose}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                  >
                    <Alert
                      icon={<CircularProgress color="primary" size="1.5rem" />}
                      severity="info"
                      sx={{ width: '100%' }}
                    >
                      Loading
                    </Alert>
                  </Snackbar>
                ))}
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <MainView />
                </LocalizationProvider>

            </React.Suspense>
          </ThemeProvider>
        </UIContext.Provider>
      </Router>
    </SuperTokensWrapper>

  )
}

export default App

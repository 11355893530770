/**
 * Convert uppercase string to capital case
 * 
 * @example
 * getAbbreviation('John Doe') // returns 'Hello World'
 * 
 * @param {String=} name
 * 
 * @returns {String}
 */
export function upperToCapitalCase(uppercaseStr) {
    if (!uppercaseStr) {
        return '';
    }
    return uppercaseStr.toLowerCase()
        .split('_')
        .map(
            (word) => word.charAt(0).toUpperCase() + word.slice(1)
        ).join(' ');
}

/**
 * Get the abbreviation of a name
 * 
 * @example
 * getAbbreviation('John Doe') // returns 'JD'
 * 
 * @param {String} name
 * 
 * @returns {String}
 */
export function getAbbreviation(name) {
    return name.split(' ').map((word) => word.charAt(0)).join('');
}

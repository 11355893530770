import axiosInstance from '@/utils/axiosInstance';

const resource = 'receipt'

const receiptApi = {
  async findAll() {
    const res = await axiosInstance({
      url: `${resource}/`,
      method: 'GET',
    })
    return res.data
  },
  async findBySupplierType(type) {
    const res = await axiosInstance({
      url: `${resource}/bySupplierType?type=${type}`,
      method: 'GET',
    })
    return res.data
  },
  async findOwn() {
    const res = await axiosInstance({
      url: `${resource}/own`,
      method: 'GET',
    })
    return res.data
  },
  async findRessource() {
    const res = await axiosInstance({
      url: `${resource}/resources`,
      method: 'GET',
    })
    return res.data
  },

  async create(data) {
    const res = await axiosInstance({
      url: `${resource}/`,
      method: 'POST',
      data
    })
    return res.data
  },
  async createSupportFnReceipt(data) {
    const res = await axiosInstance({
      url: `${resource}/support-function`,
      method: 'POST',
      data
    })
    return res.data
  },
  async update(id, data) {
    const res = await axiosInstance({
      url: `${resource}/${id}`,
      method: 'PATCH',
      data
    })
    return res.data
  },
  async updateSupportFnReceipt(id, data) {
    const res = await axiosInstance({
      url: `${resource}/support-function/${id}`,
      method: 'PATCH',
      data
    })
    return res.data
  },
  async delete(id) {
    const res = await axiosInstance({
      url: `${resource}/${id}`,
      method: 'DELETE',
    })
    return res.data
  },
  async download(data) {
    const response = await axiosInstance({
      responseType: 'blob',
      url: `${resource}/download`,
      method: 'POST',
      data
    })
    const url = window.URL.createObjectURL(new Blob([response.data]))
    const anchor = document.createElement('a')
    anchor.href = url
    anchor.setAttribute('download', 'file.zip')
    document.body.appendChild(anchor)
    anchor.click()
  },
}

export default receiptApi

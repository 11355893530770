import React, { useEffect, useState } from "react";

import {
  Card,
  CardContent,
  Typography,
  Grid,
  Link,
  CardActions,
  IconButton,
  Dialog,
  Stack,
  Button,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Input,
  Autocomplete,
  MenuItem,
} from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";
import * as moment from "moment";
import { DatePicker } from "@mui/x-date-pickers";
import { getCurrencySymbol, useAsyncRequest } from "@/utils/helper";
import fileApi from "@/api/fileApi";

import receiptApi from "@/api/receipt";
import projectApi from "@/api/projects";
import appScriptApi from "@/api/app-script";
import { useSessionContext } from "supertokens-auth-react/recipe/session";
import currencyApi from "@/api/currency";
import { upperToCapitalCase } from "@/utils/string";

const ReceiptList = ({
  refresh,
  data = [],
  onDelete,
  supplierList,
  resourceList,
  resourcesAllocation,
  suppliers,
  projectAllocation,
  setProjectAllocation,
}) => {
  const { actionWrapper } = useAsyncRequest();

  let user = useSessionContext();
  const [openEdit, setOpenEdit] = useState(false);

  const [fileName, setFilename] = useState("");
  const [month, setMonth] = useState(null);

  const [projectRole, setProjectRole] = useState("");
  const [ammount, setAmmount] = useState(0);
  const [numberOfWorkdays, setNumberOfWorkdays] = useState(0);
  const [selectedFile, setSelectedFile] = useState(null);
  const [hasFileChange, setHasFileChange] = useState(false);
  const [deleteFiles, setDeleteFiles] = useState([]);
  const [files, setFiles] = useState([]);
  const [receiptID, setReceiptID] = useState(null);

  const [selectedProject, setSelectedProject] = useState(null);
  const [selectedSuplier, setSelectedSuplier] = useState(null);

  const [selectedResource, setSelectedResource] = useState(null);

  const [regenerateActivity, setRegenerateActivity] = useState(false);

  const [invoiceNumber, setInvoiceNumber] = useState("");
  const [invoiceAmmount, setInvoiceAmmount] = useState("");
  const [invoiceWithTax, setInvoiceWithTax] = useState("");
  const [invoiceDue, setInvoiceDue] = useState(null);
  const [invoiceDate, setInvoiceDate] = useState(null);
  const [selectedCurrency, setSelectedCurrency] = useState("eur");
  const [currencyRate, setCurrencyRate] = useState(1);

  const [selectType, setSelectType] = useState("ACTIVITY");

  const onClose = () => {
    setSelectType("ACTIVITY");
    setSelectedFile(null);
    setFiles([]);
    setSelectedResource(null);
    setSelectedProject(null);
    setDeleteFiles([]);
    setAmmount("");
    setProjectRole("");
    setFilename("");
    setNumberOfWorkdays("");
    setRegenerateActivity(false);
    setInvoiceDue(null);
    setInvoiceDate(null);
    setInvoiceAmmount("");
    setInvoiceNumber("");
    setInvoiceWithTax("");
    setNumberOfWorkdays("");
    setOpenEdit(false);
  };

  const onOpenEdit = (receipt) => {
    setSelectType(receipt?.type);
    if (receipt?.type === "INVOICE" || receipt?.type === "SUPPORT_FUNCTION") {
      setInvoiceDate(moment(receipt?.invoiceDate));
      setInvoiceDue(moment(receipt?.dueDate));
    }
    setMonth(moment(receipt?.month));
    setReceiptID(receipt?.id);
    setProjectRole(receipt?.projectRole);
    setAmmount(receipt?.ammount);
    setNumberOfWorkdays(receipt?.numberOfWorkdays);
    setSelectedProject(receipt?.project);
    setInvoiceAmmount(receipt?.invoiceWithoutTax);
    setCurrencyRate(receipt?.conversionRate || 1);
    setSelectedCurrency(receipt?.currency || "eur");
    setInvoiceNumber(receipt?.invoiceNumber);
    setInvoiceWithTax(receipt?.invoiceWithTax);

    if (receipt?.type === "SUPPORT_FUNCTION") {
      onSelectSupplier(receipt?.supplier);
    }

    if (receipt?.resource) {
      onSelectResource(receipt?.resource);
    }
    setFiles(receipt.files);
    setOpenEdit(true);
  };
  const handleChangeCurrency = async (e) => {
    const newSelect = e.target.value;
    setSelectedCurrency(newSelect);
    if (newSelect === "eur") {
      setCurrencyRate(1);
      return;
    }
    const d = await currencyApi();
    if (d.hasOwnProperty("eur")) {
      const rate = d["eur"];
      if (rate[newSelect]) {
        setCurrencyRate(rate[newSelect]);
      } else if (rate[newSelect.toUpperCase()]) {
        setCurrencyRate(rate[newSelect.toUpperCase()]);
      }
    } else {
      if (d.hasOwnProperty("conversion_rates")) {
        setCurrencyRate(d["conversion_rates"][newSelect.toUpperCase()]);
      }
    }
  };
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFilename(file.name);
    }
    setHasFileChange(true);
    setSelectedFile(file);
  };
  const handleClearFile = () => {
    setSelectedFile(null);
  };
  const onChangeAmountPrice = (quantity, price) => {
    if (quantity !== "" && price !== "") {
      setInvoiceAmmount(quantity * price);
    }
  };
  const deleteFile = (f) => {
    setDeleteFiles([...deleteFiles, f.id]);
    if (f?.fileType !== "UPLOAD") {
      setRegenerateActivity(true);
    }
  };

  const onSelectResource = async (newValue) => {
    const projectOfResource = await actionWrapper(() =>
      projectApi.getProjectsByResource(newValue?.id)
    );

    if (newValue?.supplier) {
      setSelectedSuplier(newValue.supplier);
    } else {
      setSelectedSuplier(null);
    }
    setSelectedResource(newValue);
    setProjectAllocation(projectOfResource);
  };

  const onSelectSupplier = async (newValue) => {
    if (newValue?.Resource && newValue?.Resource.length > 0) {
      return onSelectResource(newValue.Resource[0]);
    }
    setSelectedResource(null);
    setSelectedSuplier(newValue);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    let freelanceId = undefined;

    if (selectedResource) {
      freelanceId = selectedResource.id;
    } else {
      freelanceId = user?.accessTokenPayload?.user?.Resource?.id;
    }
    let payload = {
      files: [],
      createdById: user?.accessTokenPayload?.user?.id,
      supplierId: selectedSuplier?.id,
      freelancerId: freelanceId,
      currency: selectedCurrency,
      conversionRate: currencyRate ? Number(currencyRate.toFixed(2)) : 1,
    };

    if (selectType === "INVOICE") {
      payload = {
        ...payload,
        invoiceDate,
        dueDate: invoiceDue,
        invoiceNumber,
        invoiceWithTax: parseFloat(invoiceWithTax),
        invoiceWithoutTax: parseFloat(invoiceAmmount),
        ammount: parseFloat(ammount),
        numberOfWorkdays: parseFloat(numberOfWorkdays),
      };
    } else if (selectType === "SUPPORT_FUNCTION") {
      payload = {
        ...payload,
        invoiceDate,
        dueDate: invoiceDue,
        invoiceNumber,
        invoiceWithTax: parseFloat(invoiceWithTax),
        invoiceWithoutTax: parseFloat(invoiceAmmount),
      };
    } else {
      payload = {
        ...payload,
        clientID: selectedProject?.Client?.id,
        projectId: selectedProject?.id,
        projectRole,
        month,
      };
    }
    if (hasFileChange && selectedFile) {
      const formData = new FormData();
      formData.append("fileName", fileName);
      formData.append("createdById", user?.accessTokenPayload?.user?.id);
      formData.append("file", selectedFile);
      if (selectType !== "SUPPORT_FUNCTION") {
        formData.append("freelanceId", freelanceId);
        const res = await actionWrapper(() => fileApi.uploadFile(formData));
        payload.files.push(res.id);
      } else {
        formData.append("supplierId", selectedSuplier?.id);
        const res = await actionWrapper(() =>
          fileApi.uploadSupportFunctionReceipt(formData)
        );
        payload.files.push(res.id);
      }
    }
    if (deleteFiles.length > 0) {
      deleteFiles.forEach(async (f) => {
        await fileApi.deleteById(f);
      });
      if (regenerateActivity) {
        const generateFile = await actionWrapper(() =>
          appScriptApi.generateActivity({
            freelanceId: freelanceId,
            month,
            projectName: selectedProject?.projectName,
            clientName: selectedProject?.Client?.clientName,
            workDays: parseFloat(numberOfWorkdays),
          })
        );
        payload.files.push(generateFile?.file?.id);
      }
    }

    if (selectType === "SUPPORT_FUNCTION") {
      await actionWrapper(
        () => receiptApi.updateSupportFnReceipt(receiptID, payload),
        {
          showMessage: true,
        }
      );
    } else {
      await actionWrapper(() => receiptApi.update(receiptID, payload), {
        showMessage: true,
      });
    }
    await refresh();
    onClose();
  };
  return (
    <>
      <Grid container spacing={2}>
        {data.length > 0 ? (
          data.map((receipt) => (
            <Grid item xs={12} md={4} key={receipt.id}>
              <Card>
                <CardContent className=" min-h-[260px]">
                  <div>
                    <Typography variant="h4">
                      {receipt?.invoiceNumber}
                    </Typography>
                    <Typography variant="h4">
                      {receipt?.project?.projectName}
                    </Typography>
                    {receipt.month && (
                      <Typography variant="body2">
                        <strong>
                          Activity Period:
                          {`${moment(receipt.month).format("MMMM/YYYY")}`}
                        </strong>
                      </Typography>
                    )}
                    {receipt?.client?.clientName && (
                      <Typography variant="body2">
                        <strong>Client: {receipt?.client?.clientName}</strong>
                      </Typography>
                    )}

                    {receipt.projectRole && (
                      <Typography variant="body2">
                        <strong>Role: {receipt.projectRole}</strong>
                      </Typography>
                    )}
                    {receipt.invoiceWithoutTax !== 0 && (
                      <Typography variant="body2">
                        <strong>
                          Amount without TAX: {receipt?.invoiceWithoutTax}{" "}
                          {getCurrencySymbol(receipt?.currency)}
                        </strong>
                      </Typography>
                    )}
                    {receipt.invoiceDate && (
                      <Typography variant="body2">
                        <strong>
                          Invoice Date:{" "}
                          {moment(receipt?.invoiceDate).format("DD/MM/YYYY")}
                        </strong>
                      </Typography>
                    )}
                    {receipt.dueDate && (
                      <Typography variant="body2">
                        <strong>
                          Invoice Due Date:{" "}
                          {moment(receipt?.dueDate).format("DD/MM/YYYY")}
                        </strong>
                      </Typography>
                    )}
                    {receipt.type === "INVOICE" && (
                      <>
                        <Typography variant="body2">
                          <strong>
                            Unit Price Per Day: {receipt?.ammount}{" "}
                            {getCurrencySymbol(receipt?.currency)}
                          </strong>
                        </Typography>
                        <Typography variant="body2">
                          <strong>Workdays: {receipt?.numberOfWorkdays}</strong>
                        </Typography>
                      </>
                    )}
                    {user?.accessTokenPayload?.user?.accountRole ===
                      "ADMIN" && (
                      <Typography variant="body2">
                        <strong>
                          Type: {upperToCapitalCase(receipt?.type)}
                        </strong>
                      </Typography>
                    )}

                    <Typography variant="body2">
                      <strong>Created by: {receipt?.createBy?.email}</strong>
                    </Typography>

                    <div className=" flex flex-col pt-2">
                      {receipt.files.length > 0 && (
                        <p className=" font-medium">Files:</p>
                      )}
                      {receipt.files.length > 0 &&
                        receipt.files.map((x, i) => {
                          return x?.fileType === "UPLOAD" ? (
                            <Link
                              href={`https://drive.google.com/file/d/${x.fileUrl}/view?usp=sharing`}
                              target="_blank"
                              key={x.fileUrl}
                            >
                              Uploaded File {i + 1}
                            </Link>
                          ) : (
                            <Link
                              href={`https://docs.google.com/spreadsheets/d/${x.fileUrl}`}
                              target="_blank"
                              key={x.fileUrl}
                            >
                              Activity Report
                            </Link>
                          );
                        })}
                    </div>
                  </div>
                </CardContent>
                <CardActions className="flex justify-end">
                  <IconButton
                    aria-label="Edit"
                    onClick={() => {
                      onOpenEdit(receipt);
                    }}
                  >
                    <Edit />
                  </IconButton>
                  <IconButton
                    aria-label="Delete"
                    onClick={() => {
                      onDelete(receipt.id);
                    }}
                  >
                    <Delete />
                  </IconButton>
                </CardActions>
              </Card>
            </Grid>
          ))
        ) : (
          <div className=" w-full px-4  py-5">
            <Typography variant="p">No Invoice</Typography>
          </div>
        )}
      </Grid>
      <Dialog open={openEdit} maxWidth="sm" fullWidth={true}>
        {selectType === "INVOICE" ? (
          <form onSubmit={onSubmit}>
            <DialogTitle>Update Invoices</DialogTitle>
            <DialogContent>
              <Stack spacing={2}>
                {user?.accessTokenPayload?.user?.accountRole === "ADMIN" && (
                  <div className="flex flex-col">
                    <div className=" flex items-center mb-5  text-xl">
                      Resource:
                    </div>
                    <Stack direction={"row"} spacing={2}>
                      <Autocomplete
                        sx={{ width: 250 }}
                        value={selectedSuplier}
                        options={suppliers}
                        disabled
                        isOptionEqualToValue={(option, value) =>
                          option.id === value.id
                        }
                        onChange={(event, newValue) => {
                          if (!newValue) {
                            return;
                          }
                          onSelectSupplier(newValue);
                        }}
                        getOptionLabel={(option) => option?.supplierName || ""}
                        renderInput={(params) => (
                          <TextField
                            required
                            {...params}
                            placeholder="Supplier"
                          />
                        )}
                        renderGroup={(params) => (
                          <li key={params.key}>
                            <ul>{params.children}</ul>
                          </li>
                        )}
                      />
                      <Autocomplete
                        sx={{ width: 250 }}
                        value={selectedResource}
                        options={resourcesAllocation}
                        disabled
                        isOptionEqualToValue={(option, value) =>
                          option.id === value.id
                        }
                        onChange={(event, newValue) => {
                          if (!newValue) {
                            return;
                          }
                          onSelectResource(newValue);
                        }}
                        getOptionLabel={(option) => option?.fullName || ""}
                        renderInput={(params) => (
                          <TextField
                            required
                            {...params}
                            placeholder="Resource"
                          />
                        )}
                        renderGroup={(params) => (
                          <li key={params.key}>
                            <ul>{params.children}</ul>
                          </li>
                        )}
                      />
                    </Stack>
                  </div>
                )}
                <div className=" flex space-x-3">
                  <div className=" flex flex-col">
                    <div className=" flex items-center mb-5 align-middle  text-xl">
                      Activity Period
                    </div>
                    <Stack
                      direction={"row"}
                      spacing={1}
                      alignContent={"center"}
                      alignItems={"center"}
                    >
                      <DatePicker
                        views={["month", "year"]}
                        value={month}
                        slotProps={{
                          textField: {
                            required: true,
                            placeholder: "Month",
                          },
                        }}
                        onChange={(newValue) => {
                          setMonth(newValue);
                        }}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </Stack>
                  </div>
                </div>
                <div className=" flex space-x-3">
                  <div className=" flex flex-col">
                    <div className=" flex items-center mb-5 align-middle text-xl ">
                      Invoice Detail
                    </div>
                    <div className="flex space-y-5 flex-col">
                      <div className=" flex space-x-5">
                        <DatePicker
                          value={invoiceDate}
                          label="Invoice Date"
                          format="DD/MM/YYYY"
                          onChange={(newValue) => {
                            setInvoiceDate(newValue);
                          }}
                          slotProps={{
                            textField: {
                              required: true,
                              variant: "standard",
                              fullWidth: true,
                            },
                          }}
                        />
                        <DatePicker
                          value={invoiceDue}
                          label="Invoice Due Date"
                          format="DD/MM/YYYY"
                          onChange={(newValue) => {
                            setInvoiceDue(newValue);
                          }}
                          slotProps={{
                            textField: {
                              required: true,
                              fullWidth: true,
                              variant: "standard",
                            },
                          }}
                        />
                      </div>
                      <div className=" flex  space-x-5">
                        <TextField
                          required
                          fullWidth
                          id="numberOfWorkdays"
                          label="Number of Days Invoiced"
                          type="number"
                          variant="standard"
                          value={numberOfWorkdays}
                          onChange={(e) => {
                            setNumberOfWorkdays(e.target.value);
                            onChangeAmountPrice(e.target.value, ammount);
                          }}
                        />
                        <TextField
                          required
                          fullWidth
                          label="Invoice Number"
                          variant="standard"
                          value={invoiceNumber}
                          onChange={(e) => setInvoiceNumber(e.target.value)}
                        />

                        <TextField
                          id="outlined-select-currency"
                          select
                          sx={{
                            width: 250,
                          }}
                          label="Select"
                          defaultValue={"eur"}
                          helperText="Currency"
                          value={selectedCurrency}
                          onChange={handleChangeCurrency}
                        >
                          {["USD", "EUR", "TND"].map((option) => (
                            <MenuItem
                              key={option}
                              value={option.toLocaleLowerCase()}
                            >
                              {option}
                            </MenuItem>
                          ))}
                        </TextField>
                      </div>
                      <div className="flex space-x-5">
                        <TextField
                          required
                          fullWidth
                          id="ammount"
                          label="Unit Price Per Day (without TAX)"
                          type="number"
                          variant="standard"
                          value={ammount}
                          onChange={(e) => {
                            setAmmount(e.target.value);
                            onChangeAmountPrice(
                              numberOfWorkdays,
                              e.target.value
                            );
                          }}
                        />
                        <TextField
                          required
                          fullWidth
                          disabled
                          label="Invoice amount without tax"
                          type="number"
                          variant="standard"
                          value={invoiceAmmount}
                          onChange={(e) => setInvoiceAmmount(e.target.value)}
                        />

                        <TextField
                          required
                          fullWidth
                          id="ammount"
                          label="Invoice amount with Tax"
                          type="number"
                          variant="standard"
                          value={invoiceWithTax}
                          onChange={(e) => setInvoiceWithTax(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex border p-10 border-dotted flex-col justify-center space-y-3">
                  <div className="justify-center flex">
                    <Input
                      type="file"
                      onChange={handleFileChange}
                      accept=".pdf, .doc, .docx, .jpg, .png" // Specify the accepted file types
                      sx={{ display: "none" }} // Hide the default input appearance
                      id="file-upload"
                    />
                    <label htmlFor="file-upload">
                      <Button
                        variant="contained"
                        color="primary"
                        component="div"
                      >
                        Upload the PDF*
                      </Button>
                    </label>
                  </div>
                </div>
                {selectedCurrency !== "eur" && (
                  <p>
                    1 EUR &asymp; {currencyRate}{" "}
                    {selectedCurrency.toLocaleUpperCase()}{" "}
                  </p>
                )}
                {selectedFile && (
                  <div className="flex space-x-2">
                    <p>Selected file: {selectedFile.name}</p>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={handleClearFile}
                    >
                      Clear
                    </Button>
                  </div>
                )}
              </Stack>
            </DialogContent>
            <DialogActions>
              <Button onClick={onClose}>Cancel</Button>
              <Button type="submit">Update</Button>
            </DialogActions>
          </form>
        ) : selectType === "SUPPORT_FUNCTION" ? (
          <form onSubmit={onSubmit}>
            <DialogTitle>Update Support Function Invoices</DialogTitle>
            <DialogContent>
              <Stack spacing={2}>
                {user?.accessTokenPayload?.user?.accountRole === "ADMIN" && (
                  <div className="flex flex-col">
                    <div className="flex items-center mb-5 text-xl">
                      Resource:
                    </div>
                    <Stack direction={"row"} spacing={2}>
                      <Autocomplete
                        sx={{ width: 250 }}
                        value={selectedSuplier}
                        options={suppliers}
                        disabled
                        isOptionEqualToValue={(option, value) =>
                          option.id === value.id
                        }
                        onChange={(event, newValue) => {
                          if (!newValue) {
                            return;
                          }
                          onSelectSupplier(newValue);
                        }}
                        getOptionLabel={(option) => option?.supplierName || ""}
                        renderInput={(params) => (
                          <TextField
                            required
                            {...params}
                            placeholder="Supplier"
                          />
                        )}
                        renderGroup={(params) => (
                          <li key={params.key}>
                            <ul>{params.children}</ul>
                          </li>
                        )}
                      />
                      <Autocomplete
                        sx={{ width: 250 }}
                        value={selectedResource}
                        options={resourcesAllocation}
                        disabled
                        isOptionEqualToValue={(option, value) =>
                          option.id === value.id
                        }
                        onChange={(event, newValue) => {
                          if (!newValue) {
                            return;
                          }
                          onSelectResource(newValue);
                        }}
                        getOptionLabel={(option) => option?.fullName || ""}
                        renderInput={(params) => (
                          <TextField
                            required
                            {...params}
                            placeholder="Resource"
                          />
                        )}
                        renderGroup={(params) => (
                          <li key={params.key}>
                            <ul>{params.children}</ul>
                          </li>
                        )}
                      />
                    </Stack>
                  </div>
                )}
                <div className="flex space-x-3">
                  <div className="flex flex-col">
                    <div className=" flex items-center mb-5 align-middle text-xl ">
                      Invoice Detail
                    </div>
                    <div className="flex space-y-5 flex-col">
                      <div className=" flex space-x-5">
                        <DatePicker
                          value={invoiceDate}
                          label="Invoice Date"
                          format="DD/MM/YYYY"
                          onChange={(newValue) => {
                            setInvoiceDate(newValue);
                          }}
                          slotProps={{
                            textField: {
                              required: true,
                              variant: "standard",
                              fullWidth: true,
                            },
                          }}
                        />
                        <DatePicker
                          value={invoiceDue}
                          label="Invoice Due Date"
                          format="DD/MM/YYYY"
                          onChange={(newValue) => {
                            setInvoiceDue(newValue);
                          }}
                          slotProps={{
                            textField: {
                              required: true,
                              fullWidth: true,
                              variant: "standard",
                            },
                          }}
                        />
                      </div>
                      <div className="flex space-x-5">
                        <TextField
                          required
                          fullWidth
                          label="Invoice Number"
                          variant="standard"
                          value={invoiceNumber}
                          onChange={(e) => setInvoiceNumber(e.target.value)}
                        />

                        <TextField
                          id="outlined-select-currency"
                          select
                          sx={{
                            width: 250,
                          }}
                          label="Select"
                          defaultValue={"eur"}
                          helperText="Currency"
                          value={selectedCurrency}
                          onChange={handleChangeCurrency}
                        >
                          {["USD", "EUR", "TND"].map((option) => (
                            <MenuItem
                              key={option}
                              value={option.toLocaleLowerCase()}
                            >
                              {option}
                            </MenuItem>
                          ))}
                        </TextField>
                      </div>
                      <div className="flex space-x-5">
                        <TextField
                          required
                          fullWidth
                          label="Invoice amount without tax"
                          type="number"
                          variant="standard"
                          value={invoiceAmmount}
                          onChange={(e) => setInvoiceAmmount(e.target.value)}
                        />

                        <TextField
                          required
                          fullWidth
                          id="ammount"
                          label="Invoice amount with Tax"
                          type="number"
                          variant="standard"
                          value={invoiceWithTax}
                          onChange={(e) => setInvoiceWithTax(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className=" flex border p-10 border-dotted flex-col justify-center space-y-3">
                  <div className="justify-center flex">
                    <Input
                      type="file"
                      onChange={handleFileChange}
                      accept=".pdf, .doc, .docx, .jpg, .png" // Specify the accepted file types
                      sx={{ display: "none" }} // Hide the default input appearance
                      id="file-upload"
                    />
                    <label htmlFor="file-upload">
                      <Button
                        variant="contained"
                        color="primary"
                        component="div"
                      >
                        Upload the PDF*
                      </Button>
                    </label>
                  </div>
                </div>
                {selectedCurrency !== "eur" && (
                  <p>
                    1 EUR &asymp; {currencyRate}{" "}
                    {selectedCurrency.toLocaleUpperCase()}{" "}
                  </p>
                )}
                {selectedFile && (
                  <div className="flex space-x-2">
                    <p>Selected file: {selectedFile.name}</p>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={handleClearFile}
                    >
                      Clear
                    </Button>
                  </div>
                )}
              </Stack>
            </DialogContent>
            <DialogActions>
              <Button onClick={onClose}>Cancel</Button>
              <Button type="submit">Update</Button>
            </DialogActions>
          </form>
        ) : (
          <form onSubmit={onSubmit}>
            <DialogTitle>Upload Activity Report</DialogTitle>
            <DialogContent>
              <Stack spacing={2}>
                <div className=" flex space-x-3">
                  <div className=" flex flex-col">
                    <p className=" font-semibold text-gray-600 mb-5">Month*</p>
                    <DatePicker
                      views={["month", "year"]}
                      value={month}
                      slotProps={{
                        textField: {
                          required: true,
                        },
                      }}
                      onChange={(newValue) => {
                        setMonth(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField required {...params} helperText={null} />
                      )}
                    />
                  </div>
                  {user?.accessTokenPayload?.user?.accountRole === "ADMIN" && (
                    <div className="flex flex-col">
                      <div className="font-light flex items-center mb-5">
                        Resource:*
                      </div>
                      <Autocomplete
                        sx={{ width: 250 }}
                        value={selectedResource}
                        options={resourcesAllocation}
                        isOptionEqualToValue={(option, value) =>
                          option.id === value.id
                        }
                        onChange={(event, newValue) => {
                          if (!newValue) {
                            return;
                          }
                          onSelectResource(newValue);
                        }}
                        // groupBy={(option) => option.Client?.clientName}
                        getOptionLabel={(option) => option?.fullName || ""}
                        renderInput={(params) => (
                          <TextField
                            required
                            {...params}
                            placeholder="Resource"
                          />
                        )}
                        renderGroup={(params) => (
                          <li key={params.key}>
                            {/* <div className=" bg-gray-600 text-white">
                            {params.group}
                          </div> */}
                            <ul>{params.children}</ul>
                          </li>
                        )}
                      />
                    </div>
                  )}
                </div>
                <div className="flex space-x-2">
                  <div className="flex flex-col pt-5">
                    {/* <div className="font-light flex items-center mb-5">
                    Project Allocation:
                  </div> */}
                    <Autocomplete
                      sx={{ width: 300 }}
                      value={selectedProject}
                      options={projectAllocation}
                      required
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      onChange={(event, newValue) => {
                        if (!newValue) {
                          return;
                        }
                        setSelectedProject(newValue);
                      }}
                      groupBy={(option) => option.Client?.clientName}
                      getOptionLabel={(option) => option?.projectName || ""}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          required
                          size="small"
                          variant="standard"
                          placeholder="Allocation project"
                        />
                      )}
                      renderGroup={(params) => (
                        <li key={params.key}>
                          <div className=" bg-gray-600 text-white">
                            {params.group}
                          </div>
                          <ul>{params.children}</ul>
                        </li>
                      )}
                    />
                  </div>
                  <div className="flex flex-col">
                    {/* <div className="font-light flex items-center">
                    Project Role:
                  </div> */}
                    <TextField
                      autoFocus
                      required
                      value={projectRole}
                      id="projectRole-input"
                      label="Project Role"
                      type="name"
                      variant="standard"
                      onChange={(e) => {
                        setProjectRole(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="flex space-x-2">
                  <TextField
                    required
                    id="numberOfWorkdays"
                    label="Workdays"
                    type="number"
                    variant="standard"
                    value={numberOfWorkdays}
                    onChange={(e) => setNumberOfWorkdays(e.target.value)}
                  />
                  <TextField
                    required
                    fullWidth
                    id="ammount"
                    label="Amount Per Day (without TAX)"
                    type="number"
                    variant="standard"
                    value={ammount}
                    onChange={(e) => setAmmount(e.target.value)}
                  />
                  <TextField
                    id="outlined-select-currency"
                    select
                    sx={{
                      width: 250,
                    }}
                    label="Select"
                    defaultValue={"eur"}
                    value={selectedCurrency}
                    helperText="Currency"
                    onChange={handleChangeCurrency}
                  >
                    {["USD", "EUR", "TND"].map((option) => (
                      <MenuItem key={option} value={option.toLocaleLowerCase()}>
                        {option}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
                {selectedCurrency !== "eur" && (
                  <p>
                    1 EUR &asymp; {currencyRate}{" "}
                    {selectedCurrency.toLocaleUpperCase()}{" "}
                  </p>
                )}
                {selectedFile && (
                  <div className="flex space-x-2">
                    <p>
                      Selected file:{" "}
                      {selectedFile.name || selectedFile.fileName}
                    </p>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={handleClearFile}
                    >
                      Clear
                    </Button>
                  </div>
                )}
                <p className=" italic leading-relaxed text-gray-400">
                  NOTE: Clearing the file will create a new Activity Report when
                  updated.
                </p>
                {files
                  .filter((x) => !deleteFiles.includes(x.id))
                  .map((f, index) => (
                    <div className="flex space-x-2" key={f.id}>
                      {f?.fileType === "UPLOAD" ? (
                        <Link
                          href={`https://drive.google.com/file/d/${f.fileUrl}/view?usp=sharing`}
                          target="_blank"
                          key={f.fileUrl}
                        >
                          Uploaded File: {f.fileName}
                        </Link>
                      ) : (
                        <Link
                          href={`https://docs.google.com/spreadsheets/d/${f.fileUrl}`}
                          target="_blank"
                          key={f.fileUrl}
                        >
                          Activity Report: {f.fileName}
                        </Link>
                      )}
                      <Button
                        variant="contained"
                        size="small"
                        color="secondary"
                        onClick={() => deleteFile(f)}
                      >
                        Clear
                      </Button>
                    </div>
                  ))}
              </Stack>
            </DialogContent>
            <DialogActions>
              <Button onClick={onClose}>Cancel</Button>
              <Button type="submit">Update</Button>
            </DialogActions>
          </form>
        )}
      </Dialog>
    </>
  );
};

export default ReceiptList;

import axiosInstance from '@/utils/axiosInstance';

const resource = 'supplier'

const supplierApi = {
  async findAll() {
    const res = await axiosInstance({
      url: `${resource}/`,
      method: 'GET',
    })
    return res.data
  },
  async findByType(type) {
    const res = await axiosInstance({
      url: `${resource}/byType?type=${type}`,
      method: 'GET',
    })
    return res.data
  },
  async findOne(id) {
    const res = await axiosInstance({
      url: `${resource}/${id}`,
      method: 'GET',
    })
    return res.data
  },

  async create(data) {
    const res = await axiosInstance({
      url: `${resource}/`,
      method: 'POST',
      data
    })
    return res.data
  },
  async assign(id, data) {
    const res = await axiosInstance({
      url: `${resource}/${id}`,
      method: 'POST',
      data
    })
    return res.data
  },
  async unassign(id, data) {
    const res = await axiosInstance({
      url: `${resource}/unassign/${id}`,
      method: 'POST',
      data
    })
    return res.data
  },
  async update(id, data) {
    const res = await axiosInstance({
      url: `${resource}/${id}`,
      method: 'PATCH',
      data
    })
    return res.data
  },
  async delete(id) {
    const res = await axiosInstance({
      url: `${resource}/${id}`,
      method: 'DELETE',
    })
    return res.data
  },
}

export default supplierApi

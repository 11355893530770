import axios from "axios"

export const currencySymbol = [
  {
    code: 'USD',
    symbol: '$',
  },
  {
    code: 'EUR',
    symbol: '€',
  },
]

// Function to recursively try fetching URLs
const currencyApi = async () => {
  let success = false
  let res = {}
  const currencyUrls = [
    'https://cdn.jsdelivr.net/npm/@fawazahmed0/currency-api@latest/v1/currencies/eur.min.json',
    'https://latest.currency-api.pages.dev/v1/currencies/eur.min.json',
    'https://cdn.jsdelivr.net/npm/@fawazahmed0/currency-api@latest/v1/currencies/eur.json',
    'https://latest.currency-api.pages.dev/v1/currencies/eur.json',
    'https://v6.exchangerate-api.com/v6/7443083ae30828285ea24a2e/latest/EUR',
  ];
  for (const url of currencyUrls) {
    if (success) break; // If data has been received, exit the loop
    try {
      const response = await axios.get(url);
      success = true;
      res = response.data
      return response.data;
    } catch (error) {
      console.error(`Error fetching data from ${url}: ${error.message}`);
    }
  }
  return res
};

export default currencyApi

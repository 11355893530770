import axiosInstance from '@/utils/axiosInstance';

const resource = 'files'

const fileApi = {
  async findAll() {
    const res = await axiosInstance({
      url: `${resource}/`,
      method: 'GET',
    })
    return res.data
  },
  async deleteById(id) {
    const res = await axiosInstance({
      url: `${resource}/${id}`,
      method: 'DELETE',
    })
    return res.data
  },
  async uploadFile(data) {
    const res = await axiosInstance({
      url: `${resource}/`,
      method: 'POST',
      data,
      headers: { "Content-Type": "multipart/form-data" }
    })
    return res.data
  },
  async uploadSupportFunctionReceipt(data) {
    const res = await axiosInstance({
      url: `${resource}/support-function`,
      method: 'POST',
      data,
      headers: { "Content-Type": "multipart/form-data" }
    })
    return res.data
  },
}

export default fileApi

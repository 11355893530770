import axiosInstance from '@/utils/axiosInstance';

const resouce = 'script'
const appScriptApi = {
  async generateActivity(data) {
    const res = await axiosInstance({
      url: `${resouce}/activity`,
      method: 'POST',
      data,
    })
    return res.data
  },
  
}

export default appScriptApi

import supplierApi from "@/api/supplier";
import { FolderOutlined } from "@mui/icons-material";
import { Box, IconButton, Stack, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import ReceiptList from "@/components/common/RecipeList";
import { useAsyncRequest } from "@/utils/helper";
import receiptApi from "@/api/receipt";
import projectApi from "@/api/projects";
const SingleSupplier = () => {
  const { actionWrapper } = useAsyncRequest();

  const [supplier, setSupplier] = useState();
  const { id } = useParams();

  const [resourcesAllocation, setResourcesAllocation] = useState([]);
  const [suppliers, setSuppliers] = useState([]);
  const [projectAllocation, setProjectAllocation] = useState([]);
  const fetchData = async () => {
    const res = await supplierApi.findOne(id);
    setSupplier(res);

    const resource = await actionWrapper(() => receiptApi.findRessource());
    setResourcesAllocation(resource);

    const sup = await actionWrapper(() => supplierApi.findAll());
    setSuppliers(sup);

    const project = await actionWrapper(() => projectApi.getProjects());
    setProjectAllocation(project);
  };
  const handleDelete = async (id) => {
    await actionWrapper(() => receiptApi.delete(id), { showMessage: true });
    fetchData();
  };
  useEffect(() => {
    fetchData();
  }, [id]);

  return (
    <>
      {supplier ? (
        <Stack spacing={2} justifyContent="space-between">
          <div className="text-primary font-bold text-2xl flex justify-between w-full">
            <h1>{supplier?.supplierName}</h1>
          </div>
          <Box className=" w-full mt-4">
            <Stack>
              {supplier.Resource.length > 0 ? (
                supplier.Resource.map((u) => (
                  <Stack spacing={3} key={u.id}>
                    <Typography variant="h5">
                      {u.fullName}
                      {u?.googleDriveId && (
                        <a
                          href={`https://drive.google.com/drive/folders/${u?.googleDriveId}`}
                          alt="User Folder"
                          target="__blank"
                        >
                          <IconButton aria-label="open">
                            <FolderOutlined />
                          </IconButton>
                        </a>
                      )}
                    </Typography>

                    <ReceiptList
                      refresh={fetchData}
                      data={u.SupplierReceipt}
                      onDelete={handleDelete}
                      resourcesAllocation={resourcesAllocation}
                      suppliers={suppliers}
                      projectAllocation={projectAllocation}
                      setProjectAllocation={setProjectAllocation}
                    />
                  </Stack>
                ))
              ) : (
                <Stack>
                  <p>No data to show</p>
                </Stack>
              )}
            </Stack>
          </Box>
        </Stack>
      ) : (
        <Stack direction="row" spacing={2} justifyContent="space-between">
          <div className="text-primary font-bold text-2xl">Supplier</div>
          <Box className=" w-full mt-4">
            <h1>Supplier Not Found</h1>
          </Box>
        </Stack>
      )}
    </>
  );
};

export default SingleSupplier;

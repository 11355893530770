import axiosInstance from '@/utils/axiosInstance'

const resouce = 'projects'
const projectApi = {
  async getProjects() {
    const res = await axiosInstance({
      url: `${resouce}/`,
      method: 'GET',
    })
    return res.data
  },
  async getProjectsByResource(id) {
    const res = await axiosInstance({
      url: `${resouce}/${id}/projects`,
      method: 'GET',
    })
    return res.data
  },
  async getProject(id) {
    const res = await axiosInstance({
      url: `${resouce}/${id}`,
      method: 'GET',
    })
    return res.data
  },
  async getProjectProfByClient(id) {
    const res = await axiosInstance({
      url: `${resouce}/prof/projects/${id}`,
      method: 'GET',
    })
    return res.data
  },
}
export default projectApi

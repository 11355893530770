import axios from 'axios'
import React from 'react'
import UIContext from '@/context/uiContext'
import { ACTIONS } from '@/store/uiStore'
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';

export function getFileName(response) {
  let filename = ''
  const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
  const contentType = response.headers['content-type']
  const disposition = response.headers['content-disposition']
  if (disposition && disposition.indexOf('attachment') !== -1) {
    const matches = filenameRegex.exec(disposition)
    if (matches != null && matches[1]) {
      filename = matches[1].replace(/['"]/g, '')
    }
  }
  if (!filename && contentType.indexOf('filename') !== -1) {
    const matches = filenameRegex.exec(contentType)
    if (matches != null && matches[1]) {
      filename = matches[1].replace(/['"]/g, '')
    }
  }
  return filename
}

export async function downloadFile(link) {
  return axios({
    url: link,
    method: 'GET',
    responseType: 'blob',
  })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const anchor = document.createElement('a')
      anchor.href = url
      anchor.setAttribute('download', getFileName(response))
      document.body.appendChild(anchor)
      anchor.click()
    })
    .catch(() => {
      window.open(link, 'new')
    })
}

export function useAsyncRequest() {
  const { dispatch } = React.useContext(UIContext)
  const navigate = useNavigate();

  async function actionWrapper(
    func,
    { successMessage, errorMessage, showMessage, isConfirm, refreshData, description } = {},
    linearLoading = false,
  ) {
    async function executeAction() {
      try {
        dispatch({
          type: linearLoading ? ACTIONS.toggleLinearLoading : ACTIONS.toggleLoading,
          payload: true,
        })
        const res = await func()
        if (showMessage) {
          dispatch({
            type: ACTIONS.setToastMessage,
            payload: { message: successMessage || 'Success' },
          })
        }
        return res
      } catch (error) {
        if (linearLoading) {
          navigate('/error')
        }
        dispatch({
          type: ACTIONS.setToastMessage,
          payload: {
            message: error.response
              ? error.response.data.message
              : errorMessage || 'Something went wrong!',
            type: 'error',
          },
        })
        return false
      } finally {
        dispatch({
          type: linearLoading ? ACTIONS.toggleLinearLoading : ACTIONS.toggleLoading,
          payload: false,
        })
      }
    }
    if (isConfirm) {
      dispatch({
        type: ACTIONS.setConfirmDialog,
        payload: {
          open: true,
          callback: executeAction,
          refreshData,
          description
        },
      })
    } else {
      return executeAction()
    }
    return true
  }

  return {
    actionWrapper,
  }
}

export async function downloadDriveFile(fileId) {
  await downloadFile(`${process.env.REACT_APP_API_URL}/drive/export/${fileId}`)
}

export function viewSheet(fileId) {
  window.open(`https://docs.google.com/spreadsheets/d/${fileId}/edit#gid=0`, 'new')
}

export function nest(seq, keys) {
  if (!keys.length) return seq;
  const first = keys[0];
  const rest = keys.slice(1);
  return _.mapValues(_.groupBy(seq, first), (value) => nest(value, rest));
}

export function getCurrencySymbol(currency = 'eur') {
  const symbols = {
    'eur': '€',
    'usd': '$',
    'tnd': 'د.ت' // Tunisian Dinar symbol
    // Add more currency symbols as needed
};

return symbols[currency] || '€';
}